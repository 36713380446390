<template>
  <div className="animated fadeIn">

      <!--<b-button v-show="hasFullAccess" variant="outline-success" class="btn btn-md btn-outline-success float-right" style="margin-top: 20px;  right: 115px; z-index: 100; position: absolute;" :to="{ name: 'EligibilityQuestionsCreate' }">Add Endorsement</b-button>-->

      <v-server-table :columns="columns" :data="data" :options="options" :theme="theme" id="dataTable" class="table">

          <template slot="actions" slot-scope="props">
            <b-button v-show="hasFullAccess" variant="secondary" :to="{ name: 'EligibilityQuestion', params: { id: props.row.id, product: $route.params.product } }">View</b-button>
          </template>

      </v-server-table>
  </div>
</template>

<script>
  import Vue from 'vue'
  import {ServerTable} from 'vue-tables-2'
  import axios from '../../../shared/axios/auth'

  Vue.use(ServerTable)

  export default {
    name: 'EligibilityQuestions',
    components: {
      ServerTable
    },
    data: function () {
      return {
        columns: [
          'number', 'text', 'actions'],
        data: [],
        checkedData: [],
        fields: [
        ],
        options: {
          requestFunction: (data) => {
              let url = '';

              url = '/eligibility-questions/?product=' + this.$route.params.product;

              return axios.get(url, {
                  params: data
              }).catch(error => {
                // Unauthorised
                if (error.response.status === 401) {
                  this.$store.dispatch('logout');
                }
                console.log('eligibility-questions error');
                console.log(error.response)
              });
          },
          responseAdapter: function(resp) {
            var data = this.getResponseData(resp);
            return { data: data.data.data, count: data.data.pagination.total }
          },
          headings: {
          },
          filterable: ['number'],
          sortable: [],
          sortIcon: { base:'fa', up:'fa-sort-asc', down:'fa-sort-desc', is:'fa-sort' },
        },
        useVuex: false,
        theme: 'bootstrap4',
        template: 'default'
      }
    },
    methods: {
      getEligibilityQuestions() {
        let url = '';

        url = '/eligibility-questions/?product=' + this.$route.params.product;

        axios.get(url).then(
          response => (
            this.data = response.data
          )
        ).catch(error => {

              // Unauthorised
              if (error.response.status === 401) {
                this.$store.dispatch('logout');
              }
              console.log('endorsements error');
              console.log(error.response);
          });
        return this.data
      },
    },
    computed: {
      hasAccess() {

        let access = ['full_admin',];

        let allowed = false;

        access.forEach(item => {
          if (this.$store.getters.getUserPermissions.includes(item)) {
            allowed = true
          }
        })

        return allowed

      },
      hasFullAccess() {

        let access = ['full_admin',];

        let allowed = false;

        access.forEach(item => {
          if (this.$store.getters.getUserPermissions.includes(item)) {
            allowed = true
          }
        })

        return allowed

      }
    },
    watch: {
      $route(to, from) {
        // react to route changes...
        this.$router.replace(to.fullPath).catch(error => {
          console.log(error)
        }).then(
          this.getEndorsements()
        );

      }
    },
    mounted() {
      this.getEligibilityQuestions()
    }

  };
</script>

<style>

  #dataTable  table {
    background-color: #fff;
  }
  .form-inline label {
      justify-content: left;

  }


</style>
